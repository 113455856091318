import React, { useRef, useState } from "react";
import ReactModal from "react-modal";
import "./modal.css";

const customStyles = {
  content: {
    right: "auto",
    bottom: "auto",
  },
};

function Modal({ label, note, index, onDelete, onUpdate }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const textareaRef = useRef();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Delete ${label}?`)) {
      closeModal();
      onDelete(label, index);
    }
  };

  const handleUpdate = () => {
    onUpdate(label, textareaRef.current.value);
    closeModal();
  };

  return (
    <div>
      <button onClick={openModal} style={{ background: "white" }}>
        {label}
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={label}
        style={customStyles}
      >
        <button
          style={{ width: "100%", background: "#f44336", color: "white" }}
          onClick={handleDelete}
        >
          Delete
        </button>
        <div>
          <textarea
            ref={textareaRef}
            defaultValue={note}
            rows={30}
            cols={150}
          />
        </div>
        <button style={{ width: "50%" }} onClick={closeModal}>
          Cancel
        </button>
        <button
          style={{ width: "50%", background: "#4CAF50" }}
          onClick={handleUpdate}
        >
          Save
        </button>
      </ReactModal>
    </div>
  );
}

export default Modal;
