import React, { useRef, useLayoutEffect } from "react";
import useLocalStorage from "./localstorage.hook";
import Modal from "./Modal";

const App = () => {
  const [ingredients, setIngredients] = useLocalStorage("ingredients", []);
  const [
    combinedIngredients,
    setCombinedIngredients,
  ] = useLocalStorage("combinedIngredients", [[]]);
  const [notes, setNotes] = useLocalStorage("notes", {});

  const newIngredientRef = useRef();

  useLayoutEffect(() => {
    newIngredientRef.current.focus();
  });

  const addIngredient = (e) => {
    e.preventDefault();

    if (newIngredientRef.current.value === "") return;

    setIngredients([...ingredients, newIngredientRef.current.value]);
    setCombinedIngredients([
      ...combinedIngredients.map((x) => [...x, false]),
      [...combinedIngredients.map(() => false), false],
    ]);
    newIngredientRef.current.value = "";
    newIngredientRef.current.focus();
  };

  const toggleCombinedIngredient = (i, j) => () => {
    const clone = [...combinedIngredients];
    clone[i][j] = !clone[i][j];
    clone[j][i] = clone[i][j];
    setCombinedIngredients(clone);
  };

  const updateNote = (label, note) => {
    console.log({ ...notes, [label]: note });
    setNotes({ ...notes, [label]: note });
  };

  const deleteIngredient = (label, index) => {
    setNotes({ ...notes, [label]: undefined });
    setIngredients(ingredients.filter((_, i) => i !== index));
    setCombinedIngredients(
      combinedIngredients
        .filter((_, i) => i !== index)
        .map((x) => x.filter((_, i) => i !== index))
    );
  };

  return (
    <div>
      <form onSubmit={addIngredient}>
        <button type="submit">Add Ingredient</button>
        <input type="text" ref={newIngredientRef} />
      </form>
      <table border="1">
        <thead>
          <tr>
            <th />
            {ingredients.map((x, i) => (
              <th>
                {
                  <Modal
                    label={x}
                    note={notes[x]}
                    index={i}
                    onDelete={deleteIngredient}
                    onUpdate={updateNote}
                  />
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ingredients.map((x, i) => (
            <tr>
              <td>
                <Modal
                  label={x}
                  note={notes[x]}
                  index={i}
                  onDelete={deleteIngredient}
                  onUpdate={updateNote}
                />
              </td>
              {ingredients.map((x, j) => (
                <td>
                  {i === j ? null : (
                    <input
                      type="checkbox"
                      checked={combinedIngredients[i][j]}
                      onChange={toggleCombinedIngredient(i, j)}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;
